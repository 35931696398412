import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class ThemeController extends Controller {
  static targets = [ "input" ]

  connect() {
    const theme = localStorage.getItem("color")
    if (theme != null) {
      this.apply(theme)
    }

    useClickOutside(this)
  }

  save(event) {
    const theme = event.target.value
    localStorage.setItem("color", theme)
    this.apply(theme)
  }

  apply(theme) {
    document.documentElement.style.setProperty('--color-theme', 'var(--color-' + theme + ')')
    this.select(theme)
  }

  select(theme) {
    this.inputTargets.filter(target => target.value == theme)[0].checked = true
  }

  close() {
    this.element.open = false
  }

  clickOutside(event) {
    this.close()
  }
}
