import { Controller } from 'stimulus'

export default class AppearanceController extends Controller {
  static targets = [ "input" ]

  connect() {
    const mode = localStorage.getItem("mode")
    if (mode != null) {
      this.apply(mode)
    } else {
      this.select("auto")
    }
  }

  save(event) {
    const mode = event.target.value

    if (mode == "auto") {
      localStorage.removeItem("mode")
      this.remove(mode)
    } else {
      localStorage.setItem("mode", mode)
      this.apply(mode)  
    }
  }

  apply(mode) {
    document.documentElement.dataset.mode = mode
    this.select(mode)
  }

  remove(mode) {
    document.documentElement.removeAttribute("data-mode")
    this.select(mode)
  }

  select(mode) {
    this.inputTargets.filter(target => target.value == mode)[0].checked = true
  }
}
