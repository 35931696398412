import { Controller } from 'stimulus'

export default class ScrollController extends Controller {
  static classes = [ "on" ]
  
  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      this.element.classList.toggle(this.onClass, entry.isIntersecting)
    })
  }
}
