import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class NavController extends Controller {

  connect() {
    useClickOutside(this)
  }

  close() {
    this.element.open = false
  }

  clickOutside(event) {
    this.close()
  }
}

