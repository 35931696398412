import { Application } from "stimulus"

import AppearanceController from "./controllers/appearance-controller"
import NavController from "./controllers/nav-controller"
import ScrollController from "./controllers/scroll-controller"
import ThemeController from "./controllers/theme-controller"
import ToggleController from "./controllers/toggle-controller"

import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useClickOutside(this)
  }
}

window.Stimulus = Application.start()
Stimulus.register("appearance", AppearanceController)
Stimulus.register("nav", NavController)
Stimulus.register("scroll", ScrollController)
Stimulus.register("theme", ThemeController)
Stimulus.register("toggle", ToggleController)
